import React from 'react';

import CaliceoLink from 'components/buttons/CaliceoLink';
import './articleTextVisual.scss';

const ArticleTextVisual = (props) => {
  const { data } = props;
  return (
    <div className="container center-presentation--section article-presentation">
      <div className="center-presentation--wrapper">
        <div className="center-presentation-image--wrapper" style={{ order: data.fieldMepGaucheDroite === '1' ? 1 : 2 }}>
        {/* <div className="center-presentation-image--wrapper" style={{ order: data.fieldMepGaucheDroite === '1' ? 1 : 1 }}> */}
          <img
            src={data.fieldAccrocheImage?.entity?.fieldMediaImage.url}
            alt={data.fieldAccrocheTitre?.processed}
            className="center-presentation-image"
          />
        </div>
        <div className="center-presentation--content" style={{ order: data.fieldMepGaucheDroite === '1' ? 2 : 1 }}>
        {/* <div className="center-presentation--content" style={{ order: data.fieldMepGaucheDroite === '1' ? 1 : 1 }}> */}
          <div
            className="center-presentation--title"
            dangerouslySetInnerHTML={{
              __html: data.fieldAccrocheTitre?.processed,
            }}
          />
          {data.fieldAccrocheSoustitre?.processed ? (
            <div
              className="center-presentation--subtitle"
              dangerouslySetInnerHTML={{
                __html: data.fieldAccrocheSoustitre?.processed,
              }}
            />
          ) : (
            <div style={{ height: '30px' }} />
          )}
          <div
            className="center-presentation--description"
            dangerouslySetInnerHTML={{
              __html: data.fieldTexte?.processed,
            }}
          />
          <div className="center-presentation--buttons-wrapper">
            {data.fieldAccrocheLien?.url && (
              <CaliceoLink
                primaryButton
                url={
                  data.fieldAccrocheLien.url.path
                }
                title={data.fieldAccrocheLien.title}
                classNames="mr-0 mr-md-3"
              />
            )}
            {data.fieldAccrocheDeuxiemeLien?.url && (
              <CaliceoLink
                primaryButton={false}
                url={
                  data.fieldAccrocheDeuxiemeLien.url.path
                }
                title={data.fieldAccrocheDeuxiemeLien.title}
                classNames="secondary-caliceo--button"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleTextVisual;
