import React, { useState } from 'react';
import gql from 'graphql-tag';
import useWindowWidth from 'hooks/use-window-width';
import CaliceoButton from 'components/buttons/CaliceoButton';

import { useQuery } from '@apollo/react-hooks';

const ArticleSupplySlide = (props) => {
  const { data, handleOfferClick } = props;

  const [windowWidth, setWindowWidth] = useState(0);
  useWindowWidth({ setWindowWidth });

  const OFFER_QUERY = gql`
  query OfferQuery($offerId: String!){
    nodeById(id: $offerId) {
      ... on NodeOffre {
        nid
        title
        status
        fieldAquaoOid
        fieldDomainAccess {
          entity {
           entityId
          }
        }
        fieldAccrocheTexte {
          processed
        }
        fieldAccrocheFondImage {
          entity {
            ... on MediaImage {
              fieldMediaImage {
                title
                url
              }
            }
          }
        }
      }
    }
  }`;

  const { data: offerData, loading, error } = useQuery(OFFER_QUERY, {
    variables: {
      offerId: data.itemNid,
    },
  });

  if (windowWidth < 769) {
    return (
      <>
        <div className="slider-offer-mobile">
          <img className="slider-offer-mobile--image" src={data.itemCoverImage} alt={data.fieldAccrocheTitre?.processed} />
          <h3 className="slider-offer-mobile--title" dangerouslySetInnerHTML={{ __html: data.fieldAccrocheTitre?.processed}} />
        </div>
        <div className="slider-offer-mobile--body">
          <div className="slider-offer-mobile--description" dangerouslySetInnerHTML={{ __html: data.fieldAccrocheSoustitre?.processed }} />
          <CaliceoButton
            primaryButton
            onClick={(e) => handleOfferClick(e, data.selectedVariantOid, data)}
            title="Voir l'offre"
          />
        </div>
      </>
    );
  }

  return (
    <div className="slider-offer">
      <img className="slider-offer--image" src={data.itemCoverImage} alt={data.fieldAccrocheTitre?.processed} />
      <div className="slider-offer--body">
        <h3 className="slider-offer--title" dangerouslySetInnerHTML={{ __html: data.fieldAccrocheTitre?.processed}} />
        <div className="slider-offer--description" dangerouslySetInnerHTML={{ __html: data.fieldAccrocheSoustitre?.processed }} />
        <CaliceoButton
          primaryButton
          onClick={(e) => handleOfferClick(e, data.selectedVariantOid, data)}
          title="Voir l'offre"
        />
      </div>
    </div>
  );
};

export default ArticleSupplySlide;
