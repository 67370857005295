import React from 'react';
import Slider from 'react-slick';
import { Divider } from 'antd';

import OffersRightArrow from 'components/offer-slider-arrows/OffersRightArrow';
import OffersLeftArrow from 'components/offer-slider-arrows/OffersLeftArrow';

import './articleSlider.scss';

const ArticleSlider = (props) => {
  const { data } = props;
  const settings = {
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 6,
    arrows: true,
    autoplay: true,
    speed: 10000,
    nextArrow: <OffersRightArrow />,
    prevArrow: <OffersLeftArrow />,
  };

  const getSliderSizeClassName = (size) => {
    if (size === 3) {
      return 'three-items';
    }
    if (size === 4) {
      return 'four-items';
    }
    if (size === 5) {
      return 'five-items';
    }
    return '';
  };

  return (
    <div className="container">
      <div className="article-slider--wrapper">
        <Divider plain className="article-slider--divider">
          {data.fieldArticleSectionTitre.processed}
        </Divider>
        {data.fieldArtileSectionImage.length < 6 ? (
          <div className="article-slider-row">
            {data.fieldArtileSectionImage.map(({ entity, i }) => (
              <div className={`field-article-slide ${getSliderSizeClassName(data.fieldArtileSectionImage.length)}`} key={`${entity.fieldMediaImage.url}-${i}`}>
                <img src={entity.fieldMediaImage.url} key={entity.fieldMediaImage.url} alt="" />
              </div>
            ))}
          </div>
        ) : (
          <Slider {...settings} className="article-slider">
            {data.fieldArtileSectionImage.map(({ entity, i }) => (
              <div className="field-article-slide" key={`${entity.fieldMediaImage.url}-${i}`}>
                <img src={entity.fieldMediaImage.url} key={entity.fieldMediaImage.url} alt="" />
              </div>
            ))}
          </Slider>
        )}
      </div>
    </div>
  );
};

export default ArticleSlider;
