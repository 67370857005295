import gql from 'graphql-tag';

const CHECK_CENTERS_BY_AQUAO = gql`
  query CheckCentersByAquao($aquaoOid: [String]) {
    nodeQuery(
      filter: {
        conditions: [
          { field: "field_aquao_oid", value: $aquaoOid }
          { field: "type", value: "offre" }
        ]
      }
    ) {
      entities {
        entityId
        entityBundle
        ... on NodeOffre {
          status
          path {
            alias
          }
          fieldDomainAccess {
            entity {
              entityId
            }
          }
        }
      }
    }
  }
`;

export default CHECK_CENTERS_BY_AQUAO;
