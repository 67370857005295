import React, { useEffect, useState, useCallback } from 'react';
import { Router } from '@reach/router';
import { APIWOI } from 'api';
import CMSPage from 'components/cms-page';

import { getCenterName } from 'utils/location';
import { centerCookieIds } from 'utils/constants';

const CMSBase = (props) => {
  const { location } = props;
  const [cmsPageId, setCmsPageId] = useState('');
  const [cmsPageAlias, setCmsPageAlias] = useState('');
  const [cid, setCid] = useState('');

  const getPageAlias = useCallback(() => {
    const urlFragments = location.pathname;
    setCmsPageAlias(urlFragments);
  }, [location.pathname]);

  useEffect(() => {
    const centerName = getCenterName(location);
    getPageAlias();
    if (centerName) {
      setCid(centerCookieIds[`centre-${centerName}`].cid);
    }
  }, [location]);

  const getPageId = (alias) => APIWOI.get(`/fuopefjeh?path=${alias}&cid=${cid}`).then((res) => {
    if (res.data.status === 1) {
      setCmsPageId(res.data.responseData.id);
    }
  });

  useEffect(() => {
    if (cmsPageAlias && cid) {
      getPageId(cmsPageAlias);
    }
  }, [cmsPageAlias, cid]);

  return (
    <Router basepath="/pages">
      <CMSPage path="/:id/" cmsNid={cmsPageId} />
    </Router>
  );
};

export default CMSBase;
