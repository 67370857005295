import React from 'react';
import YouTube from 'react-youtube';

import CaliceoLink from 'components/buttons/CaliceoLink';

import getYoutubeVideoId from 'utils/getYoutubeVideoId';
import PinIcon from 'images/icons/pin.png';

import CaliceoLogo from 'images/caliceo-menu-logo.svg'

const ArticleFocusVisualSlide = (props) => {
  const { data, title, centerObject, breadcrumbs } = props;
 // console.log('final breadcrumbs', breadcrumbs);

  // const opts = {
  //   playerVars: {
  //     autoplay: 1,
  //     mute: 1,
  //     loop: 1,
  //     controls: 0,
  //     showInfo: 0,
  //     rel: 0,
  //     playsinline: 1,
  //   },
  // };

  // const onReady = (e) => {
  //   e.target.playVideo();
  // };

  // const onEnd = (e) => {
  //   e.target.playVideo();
  // };
  return (
    <div className="center-hero-slide focus-visual-slide">
      {/* {data?.fieldMiseAvantVisuel[0]?.entity?.fieldMediaOembedVideo ? (
        <div className="video-background">
          <div className="video-foreground">
            <YouTube onEnd={onEnd} videoId={getYoutubeVideoId(data.fieldMiseAvantVisuel[0].entity.fieldMediaOembedVideo)} opts={opts} onReady={onReady} />
          </div>
        </div>
      ) : (
      )} */}
      <img className="center-hero-slide--image" src={data?.fieldMediaImage?.entity.url} alt={data?.fieldMediaImage?.entity.filename} />
      <div className="container">
        <div className="center-header-slide--content">
          <img src={CaliceoLogo} className="hero-content--logo" alt="Caliceo" />
          {centerObject && (
            <div className="center-header-name">
              <img src={PinIcon} alt="Center pin" />
              {centerObject.name}
            </div>
          )}
          <h1 className="center-header-slide--title" dangerouslySetInnerHTML={{ __html: title.processed }} />
          <div className="center-header-slide--breadcrumbs">
            {breadcrumbs.map((breadcrumb) => (
              <div className="center-header-slide--breadcrumbs--breadcrumb">
                <a href={breadcrumb.url}>{breadcrumb.text == "Groupe" ? "Calicéo" : breadcrumb.text}</a>
              </div>
            ))}
          </div>

        </div>
      </div>
    </div>
  );
};

export default ArticleFocusVisualSlide;
