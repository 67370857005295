import React from 'react';

import './articleFullImage.scss';

const ArticleFullImage = (props) => {
  const { data } = props;
  return (
    <div className="article-full-page-image--wrapper">
      <img src={data.fieldImage.entity.fieldMediaImage.thumb.url} alt={data.entityBundle} />
    </div>
  );
};

export default ArticleFullImage;
