import React from 'react';
import { Collapse } from 'antd';

import { UpCircleFilled, PlusCircleFilled } from '@ant-design/icons';

const { Panel } = Collapse;

import './articleAccordions.scss';

const ArticleAccordions = (props) => {
  const { data } = props;

  const handleExpandIcon = (i) => {
    if (i.isActive) {
      return <UpCircleFilled className="fourth-zone--accordion-icon" />;
    }
    return <PlusCircleFilled className="fourth-zone--accordion-icon" />;
  };

  if (!data?.fieldZoneTitre) {
    return null;
  }

  return (
    <div className="container">
      <div className="fourth-zone--wrapper">
        <div className="fourth-zone--content cms-accordions">
          <h2 className="fourth-zone--title">{data.fieldZoneTitre}</h2>
          <div className="fourth-zone--subtitle" dangerouslySetInnerHTML={{ __html: data.fieldZoneSousTitre?.processed }} />
          <Collapse expandIcon={(i) => handleExpandIcon(i)} expandIconPosition="right" bordered={false} accordion className="fourth-zone--accordions">
            {data.fieldZoneAccordeon?.map((accordion, i) => (
              <Panel header={`${i + 1}.  ${accordion.entity?.fieldAccordeonTitre}`} key={accordion.entity?.fieldAccordeonTitre}>
                <div
                  dangerouslySetInnerHTML={{ __html: accordion.entity.fieldAccordeonText?.processed }}
                />
              </Panel>
            ))}
          </Collapse>
        </div>
      </div>
    </div>
  );
};

export default ArticleAccordions;
