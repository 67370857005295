import React, { useState, useEffect } from 'react';

import { Modal, Select } from 'antd';

import CaliceoButton from 'components/buttons/CaliceoButton';

import { getCenterByDomainAccess } from 'utils/getCentersBy';
import forwardToCenter from 'utils/forwardToCenter';
import { getHostname } from 'utils/location';

import './chooseOffersModal.scss';

const { Option } = Select;

const ChooseOffersModal = (props) => {
  const {
    offerName,
    offer,
    validDomainAccesses,
    showOffersModal,
    setShowOffersModal,
    centers,
    displayAllModalCenters,
    hostname
  } = props;

  const [selectedCenterDomain, setSelectedCenterDomain] = useState(null);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [centerObj, setCenterObj] = useState(null);

  const centersList = displayAllModalCenters
    ? centers
    : centers.filter(
      (center) => validDomainAccesses.some((_offer) => _offer.fieldDomainAccess[0]?.entity?.entityId === center.domainAccess),
    );

  const getOfferFromSelectedCenter = (val) => {
    setSelectedOffer(validDomainAccesses.find((_offer) => _offer.fieldDomainAccess[0]?.entity?.entityId === val));
  };

  function handleChange(value) {
    setSelectedCenterDomain(value);
    getOfferFromSelectedCenter(value);
  }

  useEffect(() => {
    if (selectedCenterDomain) {
      setCenterObj(getCenterByDomainAccess(centersList, selectedCenterDomain));
    }
  }, [selectedCenterDomain, centersList, validDomainAccesses]);

  if (!showOffersModal) return null;

  return (
    <Modal
      className="offers-modal"
      visible={showOffersModal}
      footer={null}
      onCancel={() => setShowOffersModal(false)}
      width={850}
    >
      <h1 className="offers-modal--title">Merci de choisir votre centre pour découvrir cette offre</h1>
      {/* <p className="offers-modal--description">
        L&apos;offre
        {` ${offerName} `}
        est disponible dans le centre de:
      </p> */}
      <Select
        onChange={handleChange}
        className="offers-modal--center-selection"
        dropdownAlign={{ offset: [0, 0] }}
        dropdownClassName="research--center-selection--dropdown"
        size="large"
      >
        {centersList.map((center) => (
          <Option key={center.centerId} value={center.domainAccess}>{center.name}</Option>
        ))}
      </Select>
      <CaliceoButton
        primaryButton
        onClick={() => {
          if (selectedCenterDomain) {
            if (displayAllModalCenters) {
              forwardToCenter(centers, centerObj?.centerId, hostname, '/offers');
            } else {
              forwardToCenter(centers, centerObj?.centerId, hostname, `/produit${selectedOffer?.path.alias}`);
            }
          }
        }}
        title="Choisir ce centre"
        classNames="offers-modal--confirmation-btn"
      />
    </Modal>
  );
};

export default ChooseOffersModal;
