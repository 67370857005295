const initialState = {
  values: [],
  isSubmitting: false,
};

const setValues = (values) => ({
  type: 'SET_VALUES',
  payload: { values },
});

function reducer(state, action) {
  switch (action.type) {
    case 'SET_VALUES':
      return {
        ...state,
        values: [
          ...state.values,
          ...action.payload.values,
        ],
      };
    default:
      return state;
  }
}

export {
  reducer, initialState, setValues,
};
