import gql from 'graphql-tag';

export const CMS_TYPE_QUERY = gql`
query CMSPageQuery($nid: [String]) {
  nodeQuery(filter: { conditions: [{ field: "nid", value: $nid }] }) {
    entities {
      __typename
    }
  }
}
`;

export const CMS_ARTICLE_QUERY = gql`
  query CMSPageQuery($nid: [String]) {
    nodeQuery(filter: { conditions: [{ field: "nid", value: $nid }] }) {
      entities {
        ... on NodeArticle {
          fieldDomainAccess {
            entity {
              entityId
            }
          }
          fieldNodeParent {
              entity {
                  nid
                  title
                  entityUrl {
                      path
                  }
              }
          }
          fieldArticleSection {
            entity {
              ... on ParagraphArticleMiseEnAvant {
                entityId
                entityBundle
                fieldArticleSectionTitre {
                  processed
                }
                fieldArticleMiseAvantVisuel {
                  entity {
                    ... on MediaImage {
                      fieldMediaImage {
                        entity {
                          filename
                          url
                        }
                      }
                    }
                    ... on MediaRemoteVideo {
                      fieldMediaOembedVideo
                    }
                    ... on MediaVideo {
                      fieldMediaVideoFile {
                        entity {
                          filename
                          url
                        }
                      }
                    }
                  }
                }
              }
              ... on ParagraphArticleTextVisual {
                entityId
                entityBundle
                fieldAccrocheTitre {
                  processed
                }
                fieldAccrocheSoustitre {
                  processed
                }
                fieldTexte {
                  processed
                }
                fieldAccrocheLien {
                  url {
                    path
                  }
                  uri
                  title
                }
                fieldAccrocheDeuxiemeLien {
                  url {
                    path
                  }
                  title
                }
                fieldAccrocheImage {
                  entity {
                    ... on MediaImage {
                      fieldMediaImage {
                        title
                        url
                      }
                    }
                  }
                }
                fieldMepGaucheDroite
              }
              ... on ParagraphArticleOnglets {
                entityId
                entityBundle
                fieldOnglet {
                  entity {
                    ... on ParagraphOffreOnglet {
                      fieldOngletTitre
                      fieldOngletTexte {
                        processed
                      }
                      fieldOngletImage {
                        entity {
                          ... on MediaImage {
                            fieldMediaImage {
                              title
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on ParagraphArticleImagePleineLargeur {
                entityId
                entityBundle
                fieldImage {
                  entity {
                    ... on MediaImage {
                      fieldMediaImage {
                        thumb: derivative(style: MAX2600X2600) {
                          url
                        }
                      }
                    }
                  }
                }
              }
              ... on ParagraphArticleDeuxBlocs {
                entityId
                entityBundle
                fieldBloc {
                  entity {
                    ... on ParagraphBlocAccrochesArticles {
                      fieldAccrocheTitre {
                        processed
                      }
                      fieldAccrocheSoustitre {
                        processed
                      }
                      fieldAccrocheLien {
                        url {
                          path
                        }
                        uri
                        title
                      }
                      fieldAccrocheImage {
                        entity {
                          ... on MediaImage {
                            fieldMediaImage {
                              title
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                }
                fieldTexte {
                  processed
                }
              }
              ... on ParagraphArticleSliderOne {
                entityId
                entityBundle
                fieldArticleSectionTitre {
                  processed
                }
                fieldArtileSectionImage {
                  entity {
                    ... on MediaImage {
                      fieldMediaImage {
                        title
                        url
                      }
                    }
                  }
                }
              }
              ... on ParagraphArticleTable {
                entityId
                entityBundle
                fieldTable {
                  processed
                }
              }
              ... on ParagraphArticlePushButton {
                entityId
                entityBundle
                fieldArticleSectionTitre {
                  processed
                }
                fieldTexte {
                  processed
                }
                fieldAccrocheLien {
                  url {
                    path
                  }
                  uri
                  title
                }
              }
              ... on ParagraphArticleColonnes {
                entityId
                entityBundle
                fieldZoneConseil {
                  entity {
                    ... on ParagraphOffreConseil {
                      fieldConseilTitre
                      fieldConseilTexte {
                        processed
                      }
                      fieldConseilIcone {
                        entity {
                          ... on MediaImage {
                            fieldMediaImage {
                              title
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on ParagraphArticleSupply {
                entityId
                entityBundle
                fieldArticleSectionTitre {
                  processed
                }
                fieldAccrocheSoustitre {
                  processed
                }
                fieldBlocAccroche {
                  entity {
                    ... on ParagraphBlocAccrochesArticles {
                      fieldAccrocheTitre {
                        processed
                      }
                      fieldAccrocheSoustitre {
                        processed
                      }
                      fieldAccrocheLien {
                        url {
                          path
                        }
                        uri
                        title
                      }
                      fieldAccrocheImage {
                        entity {
                          ... on MediaImage {
                            fieldMediaImage {
                              title
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on ParagraphArticleTexte {
                entityId
                entityBundle
                fieldArticleSectionTitre {
                  processed
                }
                fieldAccrocheLien {
                  url {
                    path
                  }
                  uri
                  title
                }
                fieldTexte {
                  processed
                }
              }
              ... on ParagraphZone4 {
                entityId
                entityBundle
                fieldZoneTitre
                fieldZoneSousTitre {
                  processed
                }
                fieldZoneAccroche {
                  processed
                }
                fieldZoneImage {
                  entity {
                    ... on MediaImage {
                      fieldMediaImage {
                        title
                        url
                      }
                    }
                  }
                }
                fieldZoneAccordeon {
                  entity {
                    ... on ParagraphOffreAccordeon {
                      fieldAccordeonTitre
                      fieldAccordeonText {
                        processed
                      }
                    }
                  }
                }
              }
              ... on ParagraphAccordeonSimple {
                entityId
                entityBundle
                fieldZoneTitre
                fieldZoneSousTitre {
                  processed
                }
                fieldZoneAccordeon {
                  entity {
                    ... on ParagraphOffreAccordeon {
                      fieldAccordeonTitre
                      fieldAccordeonText {
                        processed
                      }
                    }
                  }
                }
              }
              ... on ParagraphArticleForm {
                entityId
                entityBundle
                fieldFormTitre {
                  processed
                }
                fieldFormFields {
                  entity {
                    entityId
                  }
                }
              }
            }
          }
          fieldMetatagSurmesure {
            entity {
              ...on ParagraphMetatagSurmesure {
                fieldMetatagTitre
                fieldMetatagCentre
                fieldMetatagDescription
              }
            }
          }
        }
      }
    }
  }
`;
export const CMS_ARTICLE_PARENT_QUERY = gql`
  query CMSPageQuery($nid: [String]) {
    nodeQuery(filter: { conditions: [{ field: "nid", value: $nid }] }) {
      entities {
        ... on NodeArticle {
          fieldNodeParent {
              entity {
                  nid
                  title
                  entityUrl {
                      path
                  }
              }
          }
        }
      }
    }
  }
`;

export const CMS_EVENT_QUERY = gql`
  query CMSPageQuery($nid: [String]) {
    nodeQuery(filter: { conditions: [{ field: "nid", value: $nid }] }) {
      entities {
        ... on NodeEvenement {
          fieldDomainAccess {
            entity {
              entityId
            }
          }
          fieldArticleSection {
            entity {
              ... on ParagraphArticleMiseEnAvant {
                entityId
                entityBundle
                fieldArticleSectionTitre {
                  processed
                }
                fieldArticleMiseAvantVisuel {
                  entity {
                    ... on MediaImage {
                      fieldMediaImage {
                        entity {
                          filename
                          url
                        }
                      }
                    }
                    ... on MediaRemoteVideo {
                      fieldMediaOembedVideo
                    }
                    ... on MediaVideo {
                      fieldMediaVideoFile {
                        entity {
                          filename
                          url
                        }
                      }
                    }
                  }
                }
              }
              ... on ParagraphArticleTextVisual {
                entityId
                entityBundle
                fieldAccrocheTitre {
                  processed
                }
                fieldAccrocheSoustitre {
                  processed
                }
                fieldTexte {
                  processed
                }
                fieldAccrocheLien {
                  url {
                    path
                  }
                  uri
                  title
                }
                fieldAccrocheDeuxiemeLien {
                  url {
                    path
                  }
                  title
                }
                fieldAccrocheImage {
                  entity {
                    ... on MediaImage {
                      fieldMediaImage {
                        title
                        url
                      }
                    }
                  }
                }
                fieldMepGaucheDroite
              }
              ... on ParagraphArticleOnglets {
                entityId
                entityBundle
                fieldOnglet {
                  entity {
                    ... on ParagraphOffreOnglet {
                      fieldOngletTitre
                      fieldOngletTexte {
                        processed
                      }
                      fieldOngletImage {
                        entity {
                          ... on MediaImage {
                            fieldMediaImage {
                              title
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on ParagraphArticleImagePleineLargeur {
                entityId
                entityBundle
                fieldImage {
                  entity {
                    ... on MediaImage {
                      fieldMediaImage {
                        thumb: derivative(style: MAX2600X2600) {
                          url
                        }
                      }
                    }
                  }
                }
              }
              ... on ParagraphArticleDeuxBlocs {
                entityId
                entityBundle
                fieldBloc {
                  entity {
                    ... on ParagraphBlocAccrochesArticles {
                      fieldAccrocheTitre {
                        processed
                      }
                      fieldAccrocheSoustitre {
                        processed
                      }
                      fieldAccrocheLien {
                        url {
                          path
                        }
                        uri
                        title
                      }
                      fieldAccrocheImage {
                        entity {
                          ... on MediaImage {
                            fieldMediaImage {
                              title
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                }
                fieldTexte {
                  processed
                }
              }
              ... on ParagraphArticleSliderOne {
                entityId
                entityBundle
                fieldArticleSectionTitre {
                  processed
                }
                fieldArtileSectionImage {
                  entity {
                    ... on MediaImage {
                      fieldMediaImage {
                        title
                        url
                      }
                    }
                  }
                }
              }
              ... on ParagraphArticleTable {
                entityId
                entityBundle
                fieldTable {
                  processed
                }
              }
              ... on ParagraphArticlePushButton {
                entityId
                entityBundle
                fieldArticleSectionTitre {
                  processed
                }
                fieldTexte {
                  processed
                }
                fieldAccrocheLien {
                  url {
                    path
                  }
                  uri
                  title
                }
              }
              ... on ParagraphArticleColonnes {
                entityId
                entityBundle
                fieldZoneConseil {
                  entity {
                    ... on ParagraphOffreConseil {
                      fieldConseilTitre
                      fieldConseilTexte {
                        processed
                      }
                      fieldConseilIcone {
                        entity {
                          ... on MediaImage {
                            fieldMediaImage {
                              title
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on ParagraphArticleSupply {
                entityId
                entityBundle
                fieldArticleSectionTitre {
                  processed
                }
                fieldAccrocheSoustitre {
                  processed
                }
                fieldBlocAccroche {
                  entity {
                    ... on ParagraphBlocAccrochesArticles {
                      fieldAccrocheTitre {
                        processed
                      }
                      fieldAccrocheSoustitre {
                        processed
                      }
                      fieldAccrocheLien {
                        url {
                          path
                        }
                        uri
                        title
                      }
                      fieldAccrocheImage {
                        entity {
                          ... on MediaImage {
                            fieldMediaImage {
                              title
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on ParagraphArticleTexte {
                entityId
                entityBundle
                fieldArticleSectionTitre {
                  processed
                }
                fieldAccrocheLien {
                  url {
                    path
                  }
                  uri
                  title
                }
                fieldTexte {
                  processed
                }
              }
              ... on ParagraphZone4 {
                entityId
                entityBundle
                fieldZoneTitre
                fieldZoneSousTitre {
                  processed
                }
                fieldZoneAccroche {
                  processed
                }
                fieldZoneImage {
                  entity {
                    ... on MediaImage {
                      fieldMediaImage {
                        title
                        url
                      }
                    }
                  }
                }
                fieldZoneAccordeon {
                  entity {
                    ... on ParagraphOffreAccordeon {
                      fieldAccordeonTitre
                      fieldAccordeonText {
                        processed
                      }
                    }
                  }
                }
              }
              ... on ParagraphAccordeonSimple {
                entityId
                entityBundle
                fieldZoneTitre
                fieldZoneSousTitre {
                  processed
                }
                fieldZoneAccordeon {
                  entity {
                    ... on ParagraphOffreAccordeon {
                      fieldAccordeonTitre
                      fieldAccordeonText {
                        processed
                      }
                    }
                  }
                }
              }
              ... on ParagraphArticleForm {
                entityId
                entityBundle
                fieldFormTitre {
                  processed
                }
                fieldFormFields {
                  entity {
                    entityId
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
