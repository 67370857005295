import React from 'react';
import { navigate } from 'gatsby';
import CaliceoButton from 'components/buttons/CaliceoButton';

import './articleTwoBlocks.scss';

const ArticleTwoBlocks = (props) => {
  const { data } = props;
  return (
    <div className="container my-5">
      <div className="two-blocks--wrapper">
        {data.fieldBloc.map(({ entity }) => (
          
          <div
            key={entity.fieldAccrocheTitre.processed}
            className="quick-access--card event-card"
          >
            <img src={entity.fieldAccrocheImage.entity.fieldMediaImage.url} className="quick-access--image" alt={entity.fieldAccrocheTitre?.processed} />
            {/* <div className="quick-access--event-city">
              <span>{selectedCenter?.name}</span>
            </div> */}
            <h1 className="quick-access--title" dangerouslySetInnerHTML={{__html: entity.fieldAccrocheTitre?.processed }} />
            <div className="quick-access--description" dangerouslySetInnerHTML={{ __html: entity.fieldAccrocheSoustitre?.processed }} />
            {entity.fieldAccrocheLien?.url && (
              <CaliceoButton
                primaryButton
                onClick={() => navigate(`${entity.fieldAccrocheLien?.url.path}`)}
                title={entity.fieldAccrocheLien?.title}
              />
            )}
          </div>
        ))}
      </div>
      {data.fieldTexte?.processed && (
        <div className="two-blocks--desc" dangerouslySetInnerHTML={{ __html: data.fieldTexte?.processed}} />
      )}
    </div>
  );
};

export default ArticleTwoBlocks;
