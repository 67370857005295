import React from 'react';

const ArticleColumns = (props) => {
  const { data } = props;

  return (
    <div className="container">
      <div className="boards--wrapper article-boards">
        {data.fieldZoneConseil.map(({ entity }) => (
          <div className="board" key={entity.fieldConseilTitre}>
            <h3 className="board--title">
              {(entity.fieldConseilIcone?.entity.fieldMediaImage.url != null)?<img className="board--icon" src={entity.fieldConseilIcone?.entity.fieldMediaImage.url} alt={entity.fieldConseilTitre} />:""}
              {entity.fieldConseilTitre}
            </h3>
            <div className="board--desc" dangerouslySetInnerHTML={{ __html: entity.fieldConseilTexte?.processed }} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ArticleColumns;
