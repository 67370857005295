import React, { useState } from 'react';
import { Link } from 'gatsby';

import { RightOutlined } from '@ant-design/icons';
import CaliceoLink from 'components/buttons/CaliceoLink';
import useWindowWidth from 'hooks/use-window-width';

import './articlePushBlock.scss';

const ArticlePushBlock = (props) => {
  const { data } = props;
  const [windowWidth, setWindowWidth] = useState(0);

  useWindowWidth({ setWindowWidth });

  if (windowWidth < 769) {
    if (data.fieldTexte?.processed) {
      return (
        <div className="container">
          <div className="complex-push-block--mobile article-push-block">
            <div className="complex-push-block--body">
              <div className="complex-push-block--texts">
                <h1 className="complex-push-block--title" dangerouslySetInnerHTML={{ __html: data.fieldArticleSectionTitre.processed}} />
                <div className="complex-push-block--description" dangerouslySetInnerHTML={{ __html: data.fieldTexte?.processed }} />
              </div>
              <Link to={data.fieldAccrocheLien.url.path} className="complex-push-block--mobile-link">
                <RightOutlined />
              </Link>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="container">
        <div className="simple-push-block--mobile">
          <div className="simple-push-block--body">
            <div className="simple-push-block--mobile--content">
              <h1 className="simple-push-block--title" dangerouslySetInnerHTML={{ __html: data.fieldArticleSectionTitre.processed }} />
            </div>
            <Link to={data.fieldAccrocheLien.url.path} className="simple-push-block--mobile-link">
              <RightOutlined />
            </Link>
          </div>
        </div>
      </div>
    );
  }

  if (data.fieldTexte?.processed) {
    return (
      <div className="complex-push-block--wrapper article-push-block">
        <div className="container">
          <div className="complex-push-block--body">
            <div className="complex-push-block--texts">
              <h1 className="complex-push-block--title" dangerouslySetInnerHTML={{ __html: data.fieldArticleSectionTitre?.processed }} />
              <div className="complex-push-block--description" dangerouslySetInnerHTML={{ __html: data.fieldTexte?.processed }} />
            </div>
            <CaliceoLink
              primaryButton={false}
              classNames="secondary-caliceo--button--inverted complex-push-block--link"
              title={data.fieldAccrocheLien.title}
              url={data.fieldAccrocheLien.url.path}
              iconFill="#1f324d"
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="simple-push-block--wrapper">
      <div className="container">
        <div className="simple-push-block--body">
          <h1 className="simple-push-block--title" dangerouslySetInnerHTML={{ __html: data.fieldArticleSectionTitre?.processed }} />
          <CaliceoLink
            iconFill="#8bacdb"
            title={data.fieldAccrocheLien.title}
            url={data.fieldAccrocheLien.url.path}
            primaryButton={false}
            classNames="simple-push-block--link primary-caliceo--button--inverted"
          />
        </div>
      </div>
    </div>
  );
};

export default ArticlePushBlock;
