import React from 'react';

import './articleTable.scss';

const ArticleTable = (props) => {
  const { data } = props;

  return (
    <div className="container">
      <div className="article-table--wrapper" dangerouslySetInnerHTML={{ __html: data.fieldTable?.processed}} />
    </div>
  );
};

export default ArticleTable;
