import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import queryString from 'query-string';

import CaliceoLink from 'components/buttons/CaliceoLink';

import { useLazyQuery } from '@apollo/react-hooks';
import forwardToCenter from 'utils/forwardToCenter';
import { getHostname } from 'utils/location';
import { getCenterByDomainAccess } from 'utils/getCentersBy';
import CHECK_CENTERS_BY_AQUAO from 'hooks/use-get-centers-aquao';
import ChooseOffersModal from 'components/home-group/quick-access-block/ChooseOffersModal/ChooseOffersModal';
import OffersRightArrow from 'components/offer-slider-arrows/OffersRightArrow';
import OffersLeftArrow from 'components/offer-slider-arrows/OffersLeftArrow';
import useWindowWidth from 'hooks/use-window-width';

import ArticleSlide from './ArticleSupplySlide';

const ArticleSupply = (props) => {
  const {
    data, domainAccess, location, centers,
  } = props;

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoPlay: true,
    nextArrow: <OffersRightArrow />,
    prevArrow: <OffersLeftArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
          arrows: false,
          infinite: false,
        },
      },
    ],
  };

  const hostname = getHostname(location);

  const [showOffersModal, setShowOffersModal] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState('');
  const [validDomainAccesses, setValidDomainAccesses] = useState([]);
  const [selectedOid, setSelectedOid] = useState(null);
  const [supplyOffers, setSupplyOffers] = useState([]);
  const [centerObj, setCenterObj] = useState(null);
  const [offerAquaoOids, setOfferAquaoOids] = useState(null);

  const [windowWidth, setWindowWidth] = useState(0);
  useWindowWidth({ setWindowWidth });

  const [checkCenters] = useLazyQuery(CHECK_CENTERS_BY_AQUAO, {
    returnPartialData: true,
    onCompleted: (completedData) => setOfferAquaoOids(completedData),
  });

  const handleOfferClick = (e, selectedVariantOid, offerObj) => {
    if (selectedVariantOid) {
      checkCenters({
        variables: {
          aquaoOid: selectedVariantOid.oid,
        },
      });
    }
    setSelectedOffer(offerObj);
    setSelectedOid(selectedVariantOid);
  };

  useEffect(() => {
    setCenterObj(getCenterByDomainAccess(centers, domainAccess) || null);
  }, [domainAccess, centers]);

  useEffect(() => {
    if (offerAquaoOids) {
      const oidHasCenters = () => offerAquaoOids?.nodeQuery.entities.some(
        (offer) => offer.fieldDomainAccess[0]?.entity?.entityId,
      );
      if (!oidHasCenters() && centerObj) {
        forwardToCenter(centers, centerObj?.centerId, hostname, `/produit${selectedOffer?.itemUrl}`);
      } else {
        const getValidDomainAccesses = offerAquaoOids?.nodeQuery.entities.flatMap(
          (a) => (a.fieldDomainAccess ? a : []),
        );
        setValidDomainAccesses(getValidDomainAccesses);
        setShowOffersModal(true);
      }
    }
    if (selectedOffer && centerObj) {
      forwardToCenter(centers, centerObj?.centerId, hostname, `/produit${selectedOffer?.itemUrl}`);
    }
  }, [offerAquaoOids, centerObj, selectedOffer, hostname, centers]);

  const getQuickAccessIds = (item) => {
    if (!item.fieldAccrocheLien) return ({ ...item });
    const urlFragments = item.fieldAccrocheLien.uri.split('/');

    const itemAquaoOidFragments = urlFragments[urlFragments.length - 1].split('?');
    const qs = queryString.parse(itemAquaoOidFragments[1]);
    const urlHasQuery = urlFragments[urlFragments.length - 1]?.includes('?');
    return ({
      ...item,
      itemUrl: item.fieldAccrocheLien.url.path,
      itemNid: urlHasQuery ? itemAquaoOidFragments[0] : urlFragments[urlFragments.length - 1],
      selectedVariantOid: urlHasQuery ? qs : '',
      itemCoverImage: item.fieldAccrocheImage?.entity.fieldMediaImage.url,
    });
  };

  useEffect(() => {
    if (data) {
      const slicedNodeIds = data?.fieldBlocAccroche.map(
        ({ entity }) => getQuickAccessIds(entity),
      );
      setSupplyOffers(slicedNodeIds);
    }
  }, [data]);

  return (
    <div className="container">
      <h1 className="center-offers--title" dangerouslySetInnerHTML={{ __html: data.fieldArticleSectionTitre?.processed }} />
      <p className="center-offers--subtitle" dangerouslySetInnerHTML={{ __html: data.fieldAccrocheSoustitre?.processed }} />
      {windowWidth > 768 ? (
        <Slider {...settings} className="offers-slider center-offers-slider">
          {supplyOffers.map((supply) => (
            <ArticleSlide
              key={supply.itemNid}
              data={supply}
              handleOfferClick={handleOfferClick}
            />
          ))}
        </Slider>
      ) : (
        <div className="center-events--events-wrapper">
          <div className="cont_slider_events slider_android">
              {supplyOffers.map((supply) => (
                <div className="cont-slider-offer-mobile">
                <ArticleSlide
                  key={supply.itemNid}
                  data={supply}
                  handleOfferClick={handleOfferClick}
                />
                </div>
              ))}
          </div>
        </div>
      )}
      
      {showOffersModal && (
        <ChooseOffersModal
          offerName={selectedOffer.fieldAccrocheTitre?.processed}
          showOffersModal={showOffersModal}
          displayAllModalCenters={false}
          setShowOffersModal={setShowOffersModal}
          validDomainAccesses={validDomainAccesses}
          offer={selectedOffer}
          centers={centers}
          hostname={hostname}
          selectedOid={selectedOid}
        />
      )}
    </div>
  );
};

export default ArticleSupply;
