import React, { useEffect, useState } from "react"
import Slider from "react-slick";

import { getCenterByDomainAccess } from "utils/getCentersBy";
import ArticleFocusVisualSlide from "./ArticleFocusVisualSlide";
import "./articleFocusVisual.scss";

const ArticleFocusVisual = (props) => {
  const { domainAccess, centers, data, breadcrumbs } = props;

  const [centerObject, setCenterObject] = useState(null);

  useEffect(() => {
    setCenterObject(getCenterByDomainAccess(centers, domainAccess));
  }, [domainAccess, centers]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    vertical: true,
  };

  return (
    <div className="center-hero--section">
      <Slider {...settings} className="center-hero-slider">
        {data.fieldArticleMiseAvantVisuel.map(({ entity }) => (
          <ArticleFocusVisualSlide
            key={entity?.fieldMediaImage?.entity?.url}
            data={entity}
            breadcrumbs={breadcrumbs}
            centerObject={centerObject}
            title={data.fieldArticleSectionTitre}
          />
        ))}
      </Slider>
    </div>
  );
};

export default ArticleFocusVisual;