import React, {
  useEffect, useState, useReducer, useCallback,
} from 'react';

import regexifyString from 'regexify-string';
import ReCAPTCHA from 'react-google-recaptcha';
import API from 'api';
import qs from 'qs';
import CaliceoButton from 'components/buttons/CaliceoButton';
import {
  reducer, initialState, setValues as setValuesActionCreator,
} from './useArticleFormReducer';
import {notification } from 'antd';

import './articleForm.scss';

const ArticleForm = (props) => {
  const { data } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [recaptchaError, setRecaptchaError] = useState(false);
  const [messageSend, setMessageSend] = useState(false);
  const [formFields, setFormFields] = useState(null);
  const [recaptchaChecked, setRecaptchaChecked] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [nom_fichiers_1, setNom_fichiers_1] = useState("");
  const [nom_fichiers_2, setNom_fichiers_2] = useState("");

  const setValues = useCallback((values) => {
    dispatch(setValuesActionCreator(values));
  }, [dispatch]);

  useEffect(() => {
    API.get(`gwd?fid=${data.fieldFormFields.entity.entityId}`).then((res) => {
      setFormFields(res.data.responseData.form);
    });
    
    
  }, []);

  useEffect(() => {
    if (formFields && formFields.elements?.markup) {
      const regex = /\[.*?\]/gim;
      const fieldNames = formFields.elements?.markup['#markup'].match(regex);
      const fields = fieldNames.map((field) => (field.replace(/[[\]']+/g, '')));
      setValues(fields);
    }
  }, [formFields, setValues]);
  

  if (!formFields || !formFields.elements?.markup) {
    return null;
  }

  const form = regexifyString({
    pattern: /\[.*?\]/gim,
    decorator: (match) => {
      const fieldName = match.replace(/[[\]']+/g, '');

      switch (fieldName) {
        case 'name':
        case 'surname':
        case 'telephone':
        case 'entreprise':
        case 'nb_salaries':
          return `<input id=${fieldName} required=${formFields.elements[fieldName]['#required']} placeholder="${formFields.elements[fieldName]['#title']}" name=${fieldName} type="text" />`;
        case 'ville':
          return `<select id=${fieldName}>${Object?.keys(formFields?.elements[fieldName]['#options'])?.map((optionKey) => (
              `<option key=${optionKey} label="${formFields?.elements[fieldName]['#options'][optionKey]}" value="${formFields?.elements[fieldName]['#options'][optionKey]}" />`
            ))}</select >`;
        case 'email':
          return `<input id=${fieldName} required=${formFields.elements[fieldName]['#required']} placeholder="${formFields.elements[fieldName]['#title']}" name=${fieldName} type="email" />`;
        case 'message':
          return `<textarea id=${fieldName} required=${formFields.elements[fieldName]['#required']} placeholder="${formFields.elements[fieldName]['#title']}" name=${fieldName}></textarea>`;
        case 'rgpd':
          return `<input id=${fieldName} required=${formFields.elements[fieldName]['#required']} name=${fieldName} type="checkbox" /><span>${formFields.elements[fieldName]['#title']}</span>`;
        case 'sujet':
          return `<select id=${fieldName}>${Object.keys(formFields.elements[fieldName]['#options']).map((optionKey) => (
            `<option key=${optionKey} label="${formFields.elements[fieldName]['#options'][optionKey]}" value="${formFields.elements[fieldName]['#options'][optionKey]}" />`
          ))}</select>`;
        case 'file1':
          return `<label class="un_upload"><input id=${fieldName} required name=${fieldName} type="file" accept="application/msword, application/pdf, image/*" /> <span class="icn_upload"></span>${nom_fichiers_1}</label>`;
        case 'file2':
          return `<label class="un_upload"><input id=${fieldName} required name=${fieldName} type="file" accept="application/msword, application/pdf, image/*" /> <span class="icn_upload"></span>${nom_fichiers_2}</label>`;
        default: return match;
      }
    },
    input: formFields.elements?.markup['#markup'],
  });

  const handleReCaptchaChange = () => {
    setRecaptchaChecked(true);
    setRecaptchaError(false);
  };
  
  

  const handleSubmitCMSForm = (e) => {
    e.preventDefault();
    //console.log(document.getElementById(`${cur}`).value);
    const formValues = state.values.reduce((acc, cur) => ({ ...acc, [cur]: document.getElementById(`${cur}`).value || '' }), {});

    
    
    const formIsValid = Object.keys(formValues).every((formVal) => {
      const field = document.getElementById(formVal);
      const isFieldValid = field.checkValidity();
      if (!isFieldValid) {
        field.reportValidity();
        return false;
      }
      return true;
    });
    if (!recaptchaChecked) {
      setRecaptchaError(true);
    }
    
    const valuesToSubmit = {
      ...formValues,
      fid: data.fieldFormFields.entity.entityId,
    };
    
    var formData = new FormData();
    var imagefile1 = document.getElementById("file1");
    var imagefile2 = document.getElementById("file2");
    formData.append("data", qs.stringify(valuesToSubmit));
    if(imagefile1 !== null) {
      formData.append("file1", imagefile1.files[0]);
    }
    if(imagefile2 !== null) {
      formData.append("file2", imagefile2.files[0]);
    }
    

    if (formIsValid && recaptchaChecked) {
      setRecaptchaError(false);
      setFormSubmitting(true);
      
      API.post('/pwd', formData, {headers: {'Content-Type': 'multipart/form-data'}}).then((res) => {
        if (res.data.status === 1) {
          setFormSubmitting(false);
          setMessageSend(true);
          notification.success({
            message: 'Votre message a bien été envoyé',
          });
        }else {
          if(res.data.status > 1) {
            if(res.data.errorMessage != "" && res.data.errorMessage != undefined) {
              notification.error({
                message: res.data.errorMessage,
              });
            }
          }
        }
      });
      
    }

    
  };
  

  
  <ReCAPTCHA
      sitekey={process.env.RECAPTCHA_KEY}
      onChange={handleReCaptchaChange}
      className="article-form-recaptcha"
      hl="fr"
    />
  

  return (
    <div className="container">
      <form id="articleForm" className="article-form" onSubmit={handleSubmitCMSForm} dangerouslySetInnerHTML={{ __html: `${form.join('')}` }} />
      {<ReCAPTCHA
      sitekey={process.env.RECAPTCHA_KEY}
      onChange={handleReCaptchaChange}
      className="article-form-recaptcha"
      hl="fr"
    />}
      {recaptchaError && <p className="article-form-recaptcha-error">Vous devez vérifier que vous n&apos;êtes pas un robot.</p>}
      <CaliceoButton
        onClick={handleSubmitCMSForm}
        title={formFields.elements.actions['#submit__label']}
        primaryButton
        loading={formSubmitting}
        classNames="article-form-submit-btn"
      />
      {messageSend && <p className="message-succes-send">Votre message a bien été envoyé.</p>}
    </div>
  );
};

export default ArticleForm;
