import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { connect } from 'react-redux';
import { selectCenters } from 'redux/selectors/centers';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from 'components/layout';
import SEO from 'components/seo';

import { CMS_ARTICLE_QUERY, CMS_TYPE_QUERY, CMS_EVENT_QUERY, CMS_ARTICLE_PARENT_QUERY } from 'hooks/use-cms-content';

import FourthZone from 'components/product-details/fourth-zone/FourthZone';
import ArticleFocusVisual from './ArticleFocusVisual';
import ArticleTextVisual from './ArticleTextVisual/ArticleTextVisual';
import ArticleOnglets from './ArticleOnglets/ArticleOnglets';
import ArticleFullImage from './ArticleFullImage/ArticleFullImage';
import ArticleTwoBlocks from './ArticleTwoBlocks/ArticleTwoBlocks';
import ArticleTable from './ArticleTable/ArticleTable';
import ArticlePushBlock from './ArticlePushBlock/ArticlePushBlock';
import ArticleSlider from './ArticleSlider/ArticleSlider';
import ArticleForm from './ArticleForm/ArticleForm';
import ArticleColumns from './ArticleColumns/ArticleColumns';
import ArticleSupply from './ArticleSupply/ArticleSupply';
import ArticleText from './ArticleText/ArticleText';
import ArticleAccordions from './ArticleAccordions/ArticleAccordions';

const CMSComponents = ({
  entity, domainAccess, location, centers, breadcrumbs,
}) => {
  switch (entity.entityBundle) {
    case 'article_mise_en_avant':
      return <ArticleFocusVisual data={entity} domainAccess={domainAccess} breadcrumbs={breadcrumbs} />;
    case 'article_text_visual':
      return <ArticleTextVisual data={entity} />;
    case 'article_onglets':
      return <ArticleOnglets data={entity} />;
    case 'article_image_pleine_largeur':
      return <ArticleFullImage data={entity} />;
    case 'article_deux_blocs':
     // console.log(entity);
      return <ArticleTwoBlocks data={entity} />;
    case 'article_table':
      return <ArticleTable data={entity} />;
    case 'article_push_button':
      return <ArticlePushBlock data={entity} />;
    case 'article_slider_one':
      return <ArticleSlider data={entity} />;
    case 'article_form':
      return <ArticleForm data={entity} />;
    case 'article_colonnes':
      return <ArticleColumns data={entity} />;
    case 'article_supply':
      return <ArticleSupply centers={centers} data={entity} domainAccess={domainAccess} location={location} />;
    case 'article_texte':
      return <ArticleText data={entity} />;
    case 'zone_4': {
      const fourhtZoneData = { entity };

      return <FourthZone data={fourhtZoneData} />;
    }
    case 'accordeon_simple': {
      return <ArticleAccordions data={entity} />;
    }
    default:
      return <p>{entity.entityBundle}</p>;
  }
};



const CMSPage = (props) => {
  const { location, cmsNid, centers } = props;
  const [cmsType, setCmsType] = useState('');
  const [cmsData, setCmsData] = useState(null);
  const [cmsParentsData, setCmsParentsData] = useState(null);
  const [cmsCenterData, setCmsCenterData] = useState(null);
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const [checkCMSType] = useLazyQuery(CMS_TYPE_QUERY, {
    returnPartialData: true,
    onCompleted: (completedData) => setCmsType(completedData?.nodeQuery?.entities[0]?.__typename),
  });

  const [getCMSArticle] = useLazyQuery(CMS_ARTICLE_QUERY, {
    returnPartialData: true,
    onCompleted: (completedData) => setCmsData(completedData),
  });
  const [getCMSArticleParent] = useLazyQuery(CMS_ARTICLE_PARENT_QUERY, {
    returnPartialData: true,
    onCompleted: (completedData) => setCmsParentsData(completedData),
  });
  const [getCmsArticleParentCenter] = useLazyQuery(CMS_ARTICLE_PARENT_QUERY, {
    returnPartialData: true,
    onCompleted: (completedData) => setCmsCenterData(completedData),
  });



  const [getCMSEvent] = useLazyQuery(CMS_EVENT_QUERY, {
    returnPartialData: true,
    onCompleted: (completedData) => setCmsData(completedData),
  });

  useEffect(() => {
    let regex = /^\s*\[.*\]\s*(.*)$/;
    if (cmsData?.nodeQuery?.entities[0]?.fieldArticleSection && cmsData?.nodeQuery?.entities[0].fieldArticleSection.length > 0) {
      let currentTitle = cmsData?.nodeQuery?.entities[0]?.fieldArticleSection[0]?.entity?.fieldArticleSectionTitre?.processed || '';
      let parentBreadcrumb = cmsData?.nodeQuery?.entities[0]?.fieldNodeParent?.entity?.title || '';
      let parentBreadcrumbIsCenter = cmsData?.nodeQuery?.entities[0]?.fieldNodeParent?.entity?.__typename === "NodeCentre";
      let parentBreadcrumbUrl = cmsData?.nodeQuery?.entities[0]?.fieldNodeParent?.entity?.entityUrl?.path;
      let parentOfParentBreadcrumb = cmsParentsData?.nodeQuery?.entities[0]?.fieldNodeParent?.entity?.title || '';
      let parentOfParentBreadcrumbIsCenter = cmsParentsData?.nodeQuery?.entities[0]?.fieldNodeParent?.entity?.__typename === "NodeCentre";
      let centerBreadcrumb = cmsCenterData?.nodeQuery?.entities[0]?.fieldNodeParent?.entity?.title || '';
      let parentOfParentBreadcrumbUrl = cmsParentsData?.nodeQuery?.entities[0]?.fieldNodeParent?.entity?.entityUrl?.path;
      let breadcrumbs = [];
      let centerBreadcrumbMatches = centerBreadcrumb.match(regex);
      let parentBreadcrumbMatches = parentBreadcrumb.match(regex);
      let parentOfParentBreadcrumbMatches = parentOfParentBreadcrumb.match(regex);
      
      if (cmsData?.nodeQuery?.entities[0]?.fieldNodeParent?.entity?.__typename !== "NodeCentre" &&
        cmsParentsData?.nodeQuery?.entities[0]?.fieldNodeParent?.entity?.__typename !== "NodeCentre" &&
        cmsCenterData?.nodeQuery?.entities[0]?.fieldNodeParent?.entity?.__typename !== "NodeCentre") {
        breadcrumbs.push({url: '/', text: 'Groupe'});
      } else {
        if (parentBreadcrumbIsCenter) {
          parentBreadcrumbUrl = '/';
        } else if (parentOfParentBreadcrumbIsCenter) {
          parentOfParentBreadcrumbUrl = '/';
        }
      }
      if (centerBreadcrumbMatches && centerBreadcrumbMatches.length > 1) {
        breadcrumbs.push({url: '/', text: centerBreadcrumbMatches[1]});
      } else if (centerBreadcrumb) {
        breadcrumbs.push({url: '/', text: centerBreadcrumb});
      }
      if (parentOfParentBreadcrumbMatches && parentOfParentBreadcrumbMatches.length > 1) {
        breadcrumbs.push({url: parentOfParentBreadcrumbUrl, text: parentOfParentBreadcrumbMatches[1]});
      } else if (parentOfParentBreadcrumb) {
        breadcrumbs.push({url: parentOfParentBreadcrumbUrl, text: parentOfParentBreadcrumb});
      }
      if (parentBreadcrumbMatches && parentBreadcrumbMatches.length > 1) {
        breadcrumbs.push({url: parentBreadcrumbUrl, text: parentBreadcrumbMatches[1]});
      } else if (parentBreadcrumb) {
        breadcrumbs.push({url: parentBreadcrumbUrl, text: parentBreadcrumb});
      }
      if (currentTitle !== '') {
        breadcrumbs.push({url: '', text: currentTitle});
      }
      setBreadcrumbs(breadcrumbs);
    }
  }, [cmsData, cmsParentsData, cmsCenterData]);

  useEffect(() => {
    checkCMSType({
      variables: {
        nid: cmsNid,
      },
    });
  }, [cmsNid]);

  useEffect(() => {
    if (cmsType === 'NodeArticle') {
      getCMSArticle({
        variables: {
          nid: cmsNid,
        },
      });
    } else {
      getCMSEvent({
        variables: {
          nid: cmsNid,
        },
      });
    }
  }, [cmsNid, cmsType]);
  useEffect(() => {
    if (cmsParentsData &&
      cmsParentsData.nodeQuery &&
      cmsParentsData.nodeQuery.entities[0] &&
      cmsParentsData.nodeQuery.entities[0].fieldNodeParent &&
      cmsParentsData.nodeQuery.entities[0].fieldNodeParent.entity &&
      cmsParentsData.nodeQuery.entities[0].fieldNodeParent.entity.nid) {
      //getCmsArticleParentCenter
      getCmsArticleParentCenter({
        variables: {
          nid: cmsParentsData.nodeQuery?.entities[0]?.fieldNodeParent?.entity?.nid,
        },
      });
    } else if (cmsData &&
      cmsData.nodeQuery &&
      cmsData.nodeQuery.entities[0] &&
      cmsData.nodeQuery.entities[0].fieldNodeParent &&
      cmsData.nodeQuery.entities[0].fieldNodeParent.entity &&
      cmsData.nodeQuery.entities[0].fieldNodeParent.entity.nid) {

      getCMSArticleParent({
        variables: {
          nid: cmsData.nodeQuery?.entities[0]?.fieldNodeParent?.entity?.nid,
        },
      });
    }

  }, [cmsData, cmsParentsData]);
  if (!cmsData || !Object.keys(cmsData).length) {
    return null;
  }

  const articleSection = cmsData.nodeQuery?.entities[0]?.fieldArticleSection;
  const parent = cmsData.nodeQuery?.entities[0]?.fieldNodeParent;
  if (!articleSection) {
    return (
      <Layout location={location}>
        <SEO title="CMS PAGE" />
      </Layout>
    );
  }
  
  //console.log(articleSection);
  const regex = /(<([^>]+)>)/ig;
  const result = articleSection[1]?.entity?.fieldTexte?.processed.replace(regex, '');
  
  var titre_p = "";
  var meta_d = "";
  if(cmsData.nodeQuery?.entities[0]?.fieldMetatagSurmesure?.entity.fieldMetatagTitre != "") {
    titre_p = cmsData.nodeQuery?.entities[0]?.fieldMetatagSurmesure?.entity.fieldMetatagTitre;
  }else {
    titre_p = articleSection[0]?.entity.fieldArticleSectionTitre.processed;
  }
  
  if(cmsData.nodeQuery?.entities[0]?.fieldMetatagSurmesure?.entity.fieldMetatagDescription != "") {
    meta_d = cmsData.nodeQuery?.entities[0]?.fieldMetatagSurmesure?.entity.fieldMetatagDescription;
  }else {
    meta_d = result;
  }

  return (
    <Layout location={location}>
      <SEO title={titre_p} description={meta_d} />
      {articleSection.map(({ entity }) => (
        <CMSComponents
          entity={entity}
          key={entity.entityId}
          location={location}
          centers={centers}
          breadcrumbs={breadcrumbs}
          domainAccess={cmsData.nodeQuery?.entities[0].fieldDomainAccess[0].entity.entityId}
        />
      ))}
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  centers: selectCenters(state),
});

export default connect(mapStateToProps)(CMSPage);
