import React from 'react';

import CaliceoLink from 'components/buttons/CaliceoLink';

import './articleText.scss';

const ArticleText = (props) => {
  const { data } = props;

  return (
    <div className="container">
      <div className="article-text--wrapper">
        <h3 className="article-text--header" dangerouslySetInnerHTML={{ __html: data.fieldArticleSectionTitre?.processed}} />
        <div className="article-text--description" dangerouslySetInnerHTML={{ __html: data.fieldTexte?.processed}} />
        {data.fieldAccrocheLien?.url?.path && (
          <CaliceoLink
            url={data.fieldAccrocheLien?.url.path}
            title={data.fieldAccrocheLien?.title}
            primaryButton
          />
        )}
      </div>
    </div>
  );
};

export default ArticleText;
