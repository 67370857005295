import React, { useState, useEffect } from 'react';
import { Tabs, Collapse } from 'antd';
import { UpCircleFilled, PlusCircleFilled } from '@ant-design/icons';

import useWindowWidth from 'hooks/use-window-width';

const { TabPane } = Tabs;
const { Panel } = Collapse;

import './articleOnglets.scss';

const ArticleOnglets = (props) => {
  const { data } = props;

  const [windowWidth, setWindowWidth] = useState(0);
  const [activeTab, setActiveTab] = useState('');

  useWindowWidth({ setWindowWidth });

  useEffect(() => {
    setActiveTab(data.fieldOnglet[0].entity.fieldOngletTitre);
  }, []);

  const changeActiveTab = (tabKey) => {
    setActiveTab(tabKey);
  };

  const getImageForActiveTab = (currentActiveTab, tabs) => {
    if (!currentActiveTab) return null;
    const active = tabs.find((tab) => tab.entity.fieldOngletTitre === currentActiveTab);
    return (
      <div className="fifth-zone--image--wrapper">
        <img src={active.entity.fieldOngletImage.entity.fieldMediaImage.url} className="fifth-zone--image" alt={active.entity.fieldOngletTitre} />
      </div>
    );
  };

  const handleExpandIcon = (i) => {
    if (i.isActive) {
      return <UpCircleFilled className="fourth-zone--accordion-icon" />;
    }
    return <PlusCircleFilled className="fourth-zone--accordion-icon" />;
  };

  return (
    <div className="container">
      <div className="fifth-zone-content--wrapper">
        {windowWidth > 768 ? (
          <>
            <Tabs size="small" onChange={changeActiveTab} className={`${data.fieldOnglet.length > 3 ? 'full-width-tabs' : 'half-width-tabs'} fifth-zone--tabs`}>
              {data.fieldOnglet.map(({ entity }) => (
                <TabPane tab={entity.fieldOngletTitre} key={entity.fieldOngletTitre}>
                  <div className="fifth-zone--image--wrapper">
                    <img src={entity.fieldOngletImage.entity.fieldMediaImage.url} className="fifth-zone--image" alt={entity.fieldOngletTitre} />
                  </div>
                  <div className="fifth-zone--content" dangerouslySetInnerHTML={{ __html: entity?.fieldOngletTexte?.processed }} />
                </TabPane>
              ))}
            </Tabs>
            {data.fieldOnglet.length < 4 && getImageForActiveTab(activeTab, data.fieldOnglet)}
          </>
        ) : (
          <Collapse expandIcon={(i) => handleExpandIcon(i)} expandIconPosition="right" bordered={false} accordion className="fourth-zone--accordions">
            {data.fieldOnglet.map(({ entity }, i) => (
              <Panel header={`${i + 1}.  ${entity.fieldOngletTitre}`} key={entity.fieldOngletTitre}>
                <div dangerouslySetInnerHTML={{ __html: entity?.fieldOngletTexte?.processed }} />
              </Panel>
            ))}
          </Collapse>
        )}

      </div>
    </div>
  );
}

export default ArticleOnglets;

